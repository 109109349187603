
import { Options, Vue } from "vue-class-component";
import ImportFromExcel from "@/components/ImportFromExcel.vue";

@Options({
  data() {
    return {
      data: "",
      header: ['action','tn','group','smsnotifications','emailnotifications'],
      raw: false
    };
  },
  methods: {
    onImport(jsonData: any) {
      this.data = JSON.stringify(jsonData);
    },
  },
  components: {
    ImportFromExcel,
  },
})
export default class TestExportToExcel extends Vue {}

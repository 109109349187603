<template>
  <import-from-excel @on-import="onImport" 
  :header="header" :raw="raw" skipHeader="false"/>
  <br />
  <h4>First Sheet Data:</h4>
  {{ data }}
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ImportFromExcel from "@/components/ImportFromExcel.vue";

@Options({
  data() {
    return {
      data: "",
      header: ['action','tn','group','smsnotifications','emailnotifications'],
      raw: false
    };
  },
  methods: {
    onImport(jsonData: any) {
      this.data = JSON.stringify(jsonData);
    },
  },
  components: {
    ImportFromExcel,
  },
})
export default class TestExportToExcel extends Vue {}
</script>
